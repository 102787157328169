import React from "react";
import theme from "theme";
import { Theme, Link, Image, Strong, Text, Box, Section, Icon, Span } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, Menu } from "@quarkly/components";
import { MdArrowDownward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				一相科技官网-和流数字化-代决策
			</title>
			<meta name={"description"} content={"和流 heliu 数字化 和流数字化 一相科技官网 代决策"} />
			<meta property={"og:title"} content={"一相科技官网-和流数字化-代决策"} />
			<meta property={"og:description"} content={"让知道更自然"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E8%93%9D%E8%89%B2%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_.jpeg?v=2023-02-15T09:39:51.187Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_.png?v=2023-02-14T14:13:53.180Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_.png?v=2023-02-14T14:13:53.180Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_.png?v=2023-02-14T14:13:53.180Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_.png?v=2023-02-14T14:13:53.180Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_.png?v=2023-02-14T14:13:53.180Z"} />
		</Helmet>
		<Section
			background="#000000"
			height="auto"
			min-height="6vh"
			quarkly-title="Header"
			padding="12px 0 12px 0"
			flex-direction="column"
		>
			<Override
				slot="SectionContent"
				align-self="center"
				flex="1 1 0%"
				margin="0px 2% 0px 2%"
				sm-flex="1 1 0%"
				sm-margin="0px 2% 0px 2%"
				width="100%"
				align-content="center"
				justify-content="center"
			/>
			<Box
				display="flex"
				padding="12px 32px 12px 32px"
				justify-content="space-between"
				align-items="center"
				flex-direction="row"
				md-flex-direction="column"
				sm-flex-direction="column"
				sm-height="auto"
				sm-width="100% border-box"
				position="static"
				align-content="center"
				flex="0 1 auto"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex="0 1 auto"
					align-self="center"
					order="-1"
					flex-direction="row"
					position="static"
					sm-min-height="max-content"
				>
					<Image
						src="https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_%E5%8F%8D%E7%99%BD.png?v=2023-02-14T13:53:25.176Z"
						display="flex"
						object-fit="contain"
						width="30px"
						height="30px"
						align-self="center"
						align-items="center"
						justify-content="center"
						sm-align-self="center"
						srcSet="https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_%E5%8F%8D%E7%99%BD.png?v=2023-02-14T13%3A53%3A25.176Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_%E5%8F%8D%E7%99%BD.png?v=2023-02-14T13%3A53%3A25.176Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_%E5%8F%8D%E7%99%BD.png?v=2023-02-14T13%3A53%3A25.176Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_%E5%8F%8D%E7%99%BD.png?v=2023-02-14T13%3A53%3A25.176Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_%E5%8F%8D%E7%99%BD.png?v=2023-02-14T13%3A53%3A25.176Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_%E5%8F%8D%E7%99%BD.png?v=2023-02-14T13%3A53%3A25.176Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF-%E5%92%8C%E6%B5%81%C2%AE%20LOGO%E5%9B%BE%E6%A0%87%20200x200_%E5%8F%8D%E7%99%BD.png?v=2023-02-14T13%3A53%3A25.176Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text
						margin="0"
						md-margin="0px 0 20px 0"
						text-align="left"
						font="--lead"
						border-color="#fbfbfb"
						color="#ffffff"
						align-self="center"
						sm-align-self="center"
						sm-margin="0px 0 0px 0"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Link href="/index" color="--light" text-decoration-line="initial" target="_self">
								和流®数字化
							</Link>
						</Strong>
					</Text>
				</Box>
				<Menu
					display="flex"
					justify-content="flex-end"
					font="--base"
					font-weight="700"
					md-flex-direction="column"
					md-align-items="center"
					order="1"
					sm-align-self="center"
					sm-flex-direction="column"
					flex="0 1 auto"
					exact-active-match
				>
					<Override
						slot="link"
						text-decoration="none"
						color="--light"
						padding="6px 12px"
						border-color="--color-light"
					>
						首页
					</Override>
					<Override slot="link-aboutme" href="/aboutme" color="--light">
						关于我们
					</Override>
					<Override slot="item" color="--light" border-color="--color-light" />
				</Menu>
			</Box>
		</Section>
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(180deg,--color-dark 0%,rgba(0,0,0,0) 8.6%,transparent 98.6%) 0 0 no-repeat fixed,url(https://images.unsplash.com/photo-1506277204481-fb831bb22403?fit=crop&w=1920&q=100) bottom/cover repeat-x scroll"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
			display="flex"
			min-height="90vh"
			flex-direction="row"
			overflow-y="visible"
			height="auto"
			quarkly-title="Hero1"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				overflow-x="visible"
				flex="0 1 auto"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="10% 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				height="auto"
				align-self="flex-start"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					让知道更自然
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					提醒通知｜知识习得｜信息传递
				</Text>
				<Link
					href="#"
					padding="12px 24px 12px 24px"
					color="--dark"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
					display="none"
				>
					下划了解
				</Link>
			</Box>
			<Box
				text-align="center"
				margin="0 0 2% 0"
				flex="1 1 0%"
				color="#f7fbff"
				align-self="flex-end"
			>
				<Text margin="8px 0" text-transform="uppercase">
					我们在做什么？
				</Text>
				<Icon category="md" margin="0 auto" icon={MdArrowDownward} />
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" quarkly-title="Section1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				lg-display="flex"
				lg-justify-content="center"
				padding="0px 16px 0px 0px"
				lg-padding="0px 0px 0px 0px"
			>
				<Box
					display="grid"
					grid-template-rows="repeat(9, 60px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="16px"
					max-width="480px"
					lg-margin="0px 0px 16px 0px"
					sm-grid-template-rows="repeat(9, 10vw)"
				>
					<Image
						src="https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E6%96%B0%E9%97%BB%E7%A8%BF%E5%8F%91%E5%B8%83%E5%95%86%E5%93%81%E5%9B%BE.png?v=2023-02-13T09:48:33.154Z"
						object-fit="fill"
						width="100%"
						grid-row="1 / span 3"
						grid-column="1 / span 1"
						height="100%"
						border-radius="8px"
						srcSet="https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E6%96%B0%E9%97%BB%E7%A8%BF%E5%8F%91%E5%B8%83%E5%95%86%E5%93%81%E5%9B%BE.png?v=2023-02-13T09%3A48%3A33.154Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E6%96%B0%E9%97%BB%E7%A8%BF%E5%8F%91%E5%B8%83%E5%95%86%E5%93%81%E5%9B%BE.png?v=2023-02-13T09%3A48%3A33.154Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E6%96%B0%E9%97%BB%E7%A8%BF%E5%8F%91%E5%B8%83%E5%95%86%E5%93%81%E5%9B%BE.png?v=2023-02-13T09%3A48%3A33.154Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E6%96%B0%E9%97%BB%E7%A8%BF%E5%8F%91%E5%B8%83%E5%95%86%E5%93%81%E5%9B%BE.png?v=2023-02-13T09%3A48%3A33.154Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E6%96%B0%E9%97%BB%E7%A8%BF%E5%8F%91%E5%B8%83%E5%95%86%E5%93%81%E5%9B%BE.png?v=2023-02-13T09%3A48%3A33.154Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E6%96%B0%E9%97%BB%E7%A8%BF%E5%8F%91%E5%B8%83%E5%95%86%E5%93%81%E5%9B%BE.png?v=2023-02-13T09%3A48%3A33.154Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E6%96%B0%E9%97%BB%E7%A8%BF%E5%8F%91%E5%B8%83%E5%95%86%E5%93%81%E5%9B%BE.png?v=2023-02-13T09%3A48%3A33.154Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/SEO%E5%95%86%E5%93%81%E5%9B%BE.png?v=2023-02-13T09:58:15.767Z"
						border-radius="8px"
						object-fit="fill"
						width="100%"
						grid-column="1 / span 1"
						grid-row="4 / span 3"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/SEO%E5%95%86%E5%93%81%E5%9B%BE.png?v=2023-02-13T09%3A58%3A15.767Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/SEO%E5%95%86%E5%93%81%E5%9B%BE.png?v=2023-02-13T09%3A58%3A15.767Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/SEO%E5%95%86%E5%93%81%E5%9B%BE.png?v=2023-02-13T09%3A58%3A15.767Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/SEO%E5%95%86%E5%93%81%E5%9B%BE.png?v=2023-02-13T09%3A58%3A15.767Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/SEO%E5%95%86%E5%93%81%E5%9B%BE.png?v=2023-02-13T09%3A58%3A15.767Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/SEO%E5%95%86%E5%93%81%E5%9B%BE.png?v=2023-02-13T09%3A58%3A15.767Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/SEO%E5%95%86%E5%93%81%E5%9B%BE.png?v=2023-02-13T09%3A58%3A15.767Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%BC%81%E4%B8%9A%E9%82%AE%E7%AE%B1%E4%B8%BB%E5%9B%BE.png?v=2023-02-13T09:58:30.232Z"
						object-fit="fill"
						grid-column="2/ span 1"
						width="100%"
						height="100%"
						pointer-events="auto"
						overflow-x="clip"
						border-radius="8px"
						grid-row="2 / span 3"
						srcSet="https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%BC%81%E4%B8%9A%E9%82%AE%E7%AE%B1%E4%B8%BB%E5%9B%BE.png?v=2023-02-13T09%3A58%3A30.232Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%BC%81%E4%B8%9A%E9%82%AE%E7%AE%B1%E4%B8%BB%E5%9B%BE.png?v=2023-02-13T09%3A58%3A30.232Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%BC%81%E4%B8%9A%E9%82%AE%E7%AE%B1%E4%B8%BB%E5%9B%BE.png?v=2023-02-13T09%3A58%3A30.232Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%BC%81%E4%B8%9A%E9%82%AE%E7%AE%B1%E4%B8%BB%E5%9B%BE.png?v=2023-02-13T09%3A58%3A30.232Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%BC%81%E4%B8%9A%E9%82%AE%E7%AE%B1%E4%B8%BB%E5%9B%BE.png?v=2023-02-13T09%3A58%3A30.232Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%BC%81%E4%B8%9A%E9%82%AE%E7%AE%B1%E4%B8%BB%E5%9B%BE.png?v=2023-02-13T09%3A58%3A30.232Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%BC%81%E4%B8%9A%E9%82%AE%E7%AE%B1%E4%B8%BB%E5%9B%BE.png?v=2023-02-13T09%3A58%3A30.232Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%AE%9D%E5%A1%94%E9%83%A8%E7%BD%B2%E6%9C%8D%E5%8A%A1.png?v=2023-02-13T09:58:41.194Z"
						object-fit="fill"
						grid-column="2/ span 1"
						width="100%"
						height="100%"
						pointer-events="auto"
						overflow-x="clip"
						border-radius="8px"
						grid-row="5 / span 3"
						srcSet="https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%AE%9D%E5%A1%94%E9%83%A8%E7%BD%B2%E6%9C%8D%E5%8A%A1.png?v=2023-02-13T09%3A58%3A41.194Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%AE%9D%E5%A1%94%E9%83%A8%E7%BD%B2%E6%9C%8D%E5%8A%A1.png?v=2023-02-13T09%3A58%3A41.194Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%AE%9D%E5%A1%94%E9%83%A8%E7%BD%B2%E6%9C%8D%E5%8A%A1.png?v=2023-02-13T09%3A58%3A41.194Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%AE%9D%E5%A1%94%E9%83%A8%E7%BD%B2%E6%9C%8D%E5%8A%A1.png?v=2023-02-13T09%3A58%3A41.194Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%AE%9D%E5%A1%94%E9%83%A8%E7%BD%B2%E6%9C%8D%E5%8A%A1.png?v=2023-02-13T09%3A58%3A41.194Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%AE%9D%E5%A1%94%E9%83%A8%E7%BD%B2%E6%9C%8D%E5%8A%A1.png?v=2023-02-13T09%3A58%3A41.194Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%AE%9D%E5%A1%94%E9%83%A8%E7%BD%B2%E6%9C%8D%E5%8A%A1.png?v=2023-02-13T09%3A58%3A41.194Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="20px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				lg-width="100%"
				lg-padding="24px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					数字化企业推广方案提供商
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" font="--lead" lg-text-align="center">
					和流®能把您的
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						信息传递
					</Strong>
					推广到您的目标群体。
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						扩大知名度和影响力，帮助更多的目标客户找到您。
					</Strong>
					<br />
					<br />
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						color="--grey"
						font="300 18px/30px -apple-system, system-ui, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif"
					>
						新闻稿多平台发布｜关键词搜索优化｜营销码｜溯源码
				（微信扫描小程序码了解更多⬇️  ）
					</Span>
				</Text>
				<Image
					src="https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%92%8C%E6%B5%81%E5%B0%8F%E5%95%86%E5%BA%97%E5%B0%8F%E7%A8%8B%E5%BA%8F%E4%BA%8C%E7%BB%B4%E7%A0%81.jpeg?v=2023-02-15T08:47:36.786Z"
					display="block"
					order="0"
					align-self="flex-end"
					width="200px"
					margin="5% 0px 0px 0px"
					border-radius="50%"
					srcSet="https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%92%8C%E6%B5%81%E5%B0%8F%E5%95%86%E5%BA%97%E5%B0%8F%E7%A8%8B%E5%BA%8F%E4%BA%8C%E7%BB%B4%E7%A0%81.jpeg?v=2023-02-15T08%3A47%3A36.786Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%92%8C%E6%B5%81%E5%B0%8F%E5%95%86%E5%BA%97%E5%B0%8F%E7%A8%8B%E5%BA%8F%E4%BA%8C%E7%BB%B4%E7%A0%81.jpeg?v=2023-02-15T08%3A47%3A36.786Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%92%8C%E6%B5%81%E5%B0%8F%E5%95%86%E5%BA%97%E5%B0%8F%E7%A8%8B%E5%BA%8F%E4%BA%8C%E7%BB%B4%E7%A0%81.jpeg?v=2023-02-15T08%3A47%3A36.786Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%92%8C%E6%B5%81%E5%B0%8F%E5%95%86%E5%BA%97%E5%B0%8F%E7%A8%8B%E5%BA%8F%E4%BA%8C%E7%BB%B4%E7%A0%81.jpeg?v=2023-02-15T08%3A47%3A36.786Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%92%8C%E6%B5%81%E5%B0%8F%E5%95%86%E5%BA%97%E5%B0%8F%E7%A8%8B%E5%BA%8F%E4%BA%8C%E7%BB%B4%E7%A0%81.jpeg?v=2023-02-15T08%3A47%3A36.786Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%92%8C%E6%B5%81%E5%B0%8F%E5%95%86%E5%BA%97%E5%B0%8F%E7%A8%8B%E5%BA%8F%E4%BA%8C%E7%BB%B4%E7%A0%81.jpeg?v=2023-02-15T08%3A47%3A36.786Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%92%8C%E6%B5%81%E5%B0%8F%E5%95%86%E5%BA%97%E5%B0%8F%E7%A8%8B%E5%BA%8F%E4%BA%8C%E7%BB%B4%E7%A0%81.jpeg?v=2023-02-15T08%3A47%3A36.786Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" quarkly-title="Section2" background="rgba(0, 0, 0, 0)">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" color="rgba(0, 0, 0, 0)" />
			<Box
				width="50%"
				lg-width="100%"
				lg-display="flex"
				lg-justify-content="center"
				padding="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				display="block"
				sm-min-height="400px"
			>
				<Box
					display="grid"
					grid-template-rows="repeat(17, 50px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="1px"
					max-width="480px"
					lg-margin="0px 0px 16px 0px"
					border-radius="0px"
					sm-grid-template-rows="repeat(17, 40px)"
				>
					<Image
						src="https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%BA%BA%E8%AF%81%E8%AF%86%E5%88%AB%E6%8F%92%E4%BB%B6.png?v=2023-02-14T09:41:47.377Z"
						object-fit="contain"
						grid-row="1 / span 4"
						grid-column="1 / span 1"
						height="85%"
						border-radius="24px"
						width="85%"
						justify-self="center"
						display="inline-block"
						srcSet="https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%BA%BA%E8%AF%81%E8%AF%86%E5%88%AB%E6%8F%92%E4%BB%B6.png?v=2023-02-14T09%3A41%3A47.377Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%BA%BA%E8%AF%81%E8%AF%86%E5%88%AB%E6%8F%92%E4%BB%B6.png?v=2023-02-14T09%3A41%3A47.377Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%BA%BA%E8%AF%81%E8%AF%86%E5%88%AB%E6%8F%92%E4%BB%B6.png?v=2023-02-14T09%3A41%3A47.377Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%BA%BA%E8%AF%81%E8%AF%86%E5%88%AB%E6%8F%92%E4%BB%B6.png?v=2023-02-14T09%3A41%3A47.377Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%BA%BA%E8%AF%81%E8%AF%86%E5%88%AB%E6%8F%92%E4%BB%B6.png?v=2023-02-14T09%3A41%3A47.377Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%BA%BA%E8%AF%81%E8%AF%86%E5%88%AB%E6%8F%92%E4%BB%B6.png?v=2023-02-14T09%3A41%3A47.377Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%BA%BA%E8%AF%81%E8%AF%86%E5%88%AB%E6%8F%92%E4%BB%B6.png?v=2023-02-14T09%3A41%3A47.377Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E6%89%8B%E6%9C%BA%E5%BD%92%E5%B1%9E%E5%9C%B0%E6%9F%A5%E8%AF%A2.png?v=2023-02-14T09:41:47.379Z"
						border-radius="24px"
						object-fit="contain"
						width="85%"
						grid-column="1 / span 1"
						grid-row="5 / span 4"
						height="85%"
						justify-self="center"
						srcSet="https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E6%89%8B%E6%9C%BA%E5%BD%92%E5%B1%9E%E5%9C%B0%E6%9F%A5%E8%AF%A2.png?v=2023-02-14T09%3A41%3A47.379Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E6%89%8B%E6%9C%BA%E5%BD%92%E5%B1%9E%E5%9C%B0%E6%9F%A5%E8%AF%A2.png?v=2023-02-14T09%3A41%3A47.379Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E6%89%8B%E6%9C%BA%E5%BD%92%E5%B1%9E%E5%9C%B0%E6%9F%A5%E8%AF%A2.png?v=2023-02-14T09%3A41%3A47.379Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E6%89%8B%E6%9C%BA%E5%BD%92%E5%B1%9E%E5%9C%B0%E6%9F%A5%E8%AF%A2.png?v=2023-02-14T09%3A41%3A47.379Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E6%89%8B%E6%9C%BA%E5%BD%92%E5%B1%9E%E5%9C%B0%E6%9F%A5%E8%AF%A2.png?v=2023-02-14T09%3A41%3A47.379Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E6%89%8B%E6%9C%BA%E5%BD%92%E5%B1%9E%E5%9C%B0%E6%9F%A5%E8%AF%A2.png?v=2023-02-14T09%3A41%3A47.379Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E6%89%8B%E6%9C%BA%E5%BD%92%E5%B1%9E%E5%9C%B0%E6%9F%A5%E8%AF%A2.png?v=2023-02-14T09%3A41%3A47.379Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E7%87%83%E6%B0%94%E8%A1%A8%E8%AF%86%E5%88%AB.png?v=2023-02-14T09:41:47.375Z"
						border-radius="24px"
						object-fit="contain"
						width="85%"
						grid-column="1 / span 1"
						grid-row="9 / span 4"
						height="85%"
						justify-self="center"
						srcSet="https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E7%87%83%E6%B0%94%E8%A1%A8%E8%AF%86%E5%88%AB.png?v=2023-02-14T09%3A41%3A47.375Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E7%87%83%E6%B0%94%E8%A1%A8%E8%AF%86%E5%88%AB.png?v=2023-02-14T09%3A41%3A47.375Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E7%87%83%E6%B0%94%E8%A1%A8%E8%AF%86%E5%88%AB.png?v=2023-02-14T09%3A41%3A47.375Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E7%87%83%E6%B0%94%E8%A1%A8%E8%AF%86%E5%88%AB.png?v=2023-02-14T09%3A41%3A47.375Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E7%87%83%E6%B0%94%E8%A1%A8%E8%AF%86%E5%88%AB.png?v=2023-02-14T09%3A41%3A47.375Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E7%87%83%E6%B0%94%E8%A1%A8%E8%AF%86%E5%88%AB.png?v=2023-02-14T09%3A41%3A47.375Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E7%87%83%E6%B0%94%E8%A1%A8%E8%AF%86%E5%88%AB.png?v=2023-02-14T09%3A41%3A47.375Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%B8%AD%E5%A4%96%E4%BA%92%E8%AF%91%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T15:57:31.336Z"
						border-radius="24px"
						object-fit="contain"
						width="85%"
						grid-column="1 / span 1"
						grid-row="13/ span 4"
						height="85%"
						justify-self="center"
						srcSet="https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%B8%AD%E5%A4%96%E4%BA%92%E8%AF%91%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T15%3A57%3A31.336Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%B8%AD%E5%A4%96%E4%BA%92%E8%AF%91%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T15%3A57%3A31.336Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%B8%AD%E5%A4%96%E4%BA%92%E8%AF%91%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T15%3A57%3A31.336Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%B8%AD%E5%A4%96%E4%BA%92%E8%AF%91%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T15%3A57%3A31.336Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%B8%AD%E5%A4%96%E4%BA%92%E8%AF%91%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T15%3A57%3A31.336Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%B8%AD%E5%A4%96%E4%BA%92%E8%AF%91%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T15%3A57%3A31.336Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%B8%AD%E5%A4%96%E4%BA%92%E8%AF%91%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T15%3A57%3A31.336Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%B8%AD%E6%96%87%E8%BD%AC%E6%8B%BC%E9%9F%B3%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T16:06:32.776Z"
						border-radius="24px"
						object-fit="contain"
						grid-column="2/ span 1"
						grid-row="2 / span 4"
						width="85%"
						height="85%"
						pointer-events="auto"
						overflow-x="clip"
						justify-self="center"
						srcSet="https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%B8%AD%E6%96%87%E8%BD%AC%E6%8B%BC%E9%9F%B3%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T16%3A06%3A32.776Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%B8%AD%E6%96%87%E8%BD%AC%E6%8B%BC%E9%9F%B3%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T16%3A06%3A32.776Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%B8%AD%E6%96%87%E8%BD%AC%E6%8B%BC%E9%9F%B3%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T16%3A06%3A32.776Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%B8%AD%E6%96%87%E8%BD%AC%E6%8B%BC%E9%9F%B3%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T16%3A06%3A32.776Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%B8%AD%E6%96%87%E8%BD%AC%E6%8B%BC%E9%9F%B3%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T16%3A06%3A32.776Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%B8%AD%E6%96%87%E8%BD%AC%E6%8B%BC%E9%9F%B3%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T16%3A06%3A32.776Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E4%B8%AD%E6%96%87%E8%BD%AC%E6%8B%BC%E9%9F%B3%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T16%3A06%3A32.776Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%9C%B0%E5%9D%80%E8%BD%AC%E6%8D%A2%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T16:10:48.608Z"
						object-fit="contain"
						grid-column="2/ span 1"
						width="85%"
						height="85%"
						pointer-events="auto"
						overflow-x="clip"
						border-radius="24px"
						grid-row="6 / span 4"
						justify-self="center"
						srcSet="https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%9C%B0%E5%9D%80%E8%BD%AC%E6%8D%A2%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T16%3A10%3A48.608Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%9C%B0%E5%9D%80%E8%BD%AC%E6%8D%A2%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T16%3A10%3A48.608Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%9C%B0%E5%9D%80%E8%BD%AC%E6%8D%A2%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T16%3A10%3A48.608Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%9C%B0%E5%9D%80%E8%BD%AC%E6%8D%A2%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T16%3A10%3A48.608Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%9C%B0%E5%9D%80%E8%BD%AC%E6%8D%A2%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T16%3A10%3A48.608Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%9C%B0%E5%9D%80%E8%BD%AC%E6%8D%A2%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T16%3A10%3A48.608Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%9C%B0%E5%9D%80%E8%BD%AC%E6%8D%A2%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T16%3A10%3A48.608Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%9B%86%E8%A3%85%E7%AE%B1%E7%AE%B1%E5%8F%B7%E8%AF%86%E5%88%AB%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T16:15:05.597Z"
						object-fit="contain"
						grid-column="2/ span 1"
						width="85%"
						height="85%"
						pointer-events="auto"
						overflow-x="clip"
						border-radius="24px"
						grid-row="10 / span 4"
						justify-self="center"
						srcSet="https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%9B%86%E8%A3%85%E7%AE%B1%E7%AE%B1%E5%8F%B7%E8%AF%86%E5%88%AB%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T16%3A15%3A05.597Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%9B%86%E8%A3%85%E7%AE%B1%E7%AE%B1%E5%8F%B7%E8%AF%86%E5%88%AB%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T16%3A15%3A05.597Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%9B%86%E8%A3%85%E7%AE%B1%E7%AE%B1%E5%8F%B7%E8%AF%86%E5%88%AB%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T16%3A15%3A05.597Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%9B%86%E8%A3%85%E7%AE%B1%E7%AE%B1%E5%8F%B7%E8%AF%86%E5%88%AB%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T16%3A15%3A05.597Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%9B%86%E8%A3%85%E7%AE%B1%E7%AE%B1%E5%8F%B7%E8%AF%86%E5%88%AB%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T16%3A15%3A05.597Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%9B%86%E8%A3%85%E7%AE%B1%E7%AE%B1%E5%8F%B7%E8%AF%86%E5%88%AB%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T16%3A15%3A05.597Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E9%9B%86%E8%A3%85%E7%AE%B1%E7%AE%B1%E5%8F%B7%E8%AF%86%E5%88%AB%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-14T16%3A15%3A05.597Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E8%B4%A7%E5%B8%81%E8%BD%AC%E6%8D%A2%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-15T07:30:24.499Z"
						object-fit="contain"
						grid-column="2/ span 1"
						width="85%"
						height="85%"
						pointer-events="auto"
						overflow-x="clip"
						border-radius="24px"
						grid-row="14 / span 4"
						justify-self="center"
						srcSet="https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E8%B4%A7%E5%B8%81%E8%BD%AC%E6%8D%A2%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-15T07%3A30%3A24.499Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E8%B4%A7%E5%B8%81%E8%BD%AC%E6%8D%A2%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-15T07%3A30%3A24.499Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E8%B4%A7%E5%B8%81%E8%BD%AC%E6%8D%A2%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-15T07%3A30%3A24.499Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E8%B4%A7%E5%B8%81%E8%BD%AC%E6%8D%A2%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-15T07%3A30%3A24.499Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E8%B4%A7%E5%B8%81%E8%BD%AC%E6%8D%A2%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-15T07%3A30%3A24.499Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E8%B4%A7%E5%B8%81%E8%BD%AC%E6%8D%A2%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-15T07%3A30%3A24.499Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E8%B4%A7%E5%B8%81%E8%BD%AC%E6%8D%A2%C2%B7%E5%9C%86%E8%A7%92.png?v=2023-02-15T07%3A30%3A24.499Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="20px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				lg-width="100%"
				lg-padding="24px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					数字化管理方案提供商
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" font="--lead" lg-text-align="center">
					和流®能为您在企业数字化转型中遇到需求或难题时，与您一起识别和聚焦当前痛点和瓶颈，
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						提供数字化管理方案咨询、软件系统规划及运维服务。
					</Strong>
					<br />
					<br />
					帮助您的企业在瞬息万变的世界中，创造数字化优势，赢得领先机会。
					<br />
					<br />
					和流®有多年的信息系统研发经验，并与多个低代码平台合作，共同推动和帮助企业
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						以低成本、高效率的方式快速实现数字化转型。
						<br />
						<br />
					</Strong>
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						color="--grey"
						font="300 18px/30px -apple-system, system-ui, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif"
					>
						<Link
							href="https://support.qq.com/embed/phone/377560/faqs-more/"
							target="_blank"
							color="--grey"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							华为云、阿里云、腾讯云文件上传
						</Link>
						｜
						<Link
							href="https://hc.jiandaoyun.com/open/14944"
							color="--grey"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							target="_blank"
						>
							人证智能识别对比
						</Link>
						｜
						<Link
							href="https://hc.jiandaoyun.com/open/15130"
							color="--grey"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							target="_blank"
						>
							手机归属地查询
						</Link>
						｜
						<Link
							href="https://hc.jiandaoyun.com/open/15304"
							color="--grey"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							target="_blank"
						>
							燃气表智能识别
						</Link>
						｜
						<Link
							href="https://hc.jiandaoyun.com/open/15437"
							color="--grey"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							target="_blank"
						>
							中外互译
						</Link>
						｜
						<Link
							href="https://hc.jiandaoyun.com/open/15618"
							color="--grey"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							target="_blank"
						>
							地址与经纬度互转
						</Link>
						｜
						<Link
							href="https://hc.jiandaoyun.com/open/16034"
							color="--grey"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							target="_blank"
						>
							集装箱箱号智能识别
						</Link>
						｜
						<Link
							href="https://hc.jiandaoyun.com/open/16029"
							color="--grey"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							target="_blank"
						>
							货币转换
						</Link>
						...
					</Span>
				</Text>
			</Box>
		</Section>
		<Section background="--color-darkL1" padding="5% 0 5% 0" quarkly-title="Contacts">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 10% 0px 10%"
				lg-padding="0px 0px 0px 0px"
				flex="0 1 auto"
			>
				<Text
					margin="30px 0px 30px 0px"
					font="--headline2"
					color="--light"
					text-align="center"
					sm-font="--headline2"
				>
					联系我们
				</Text>
				<Text
					margin="0px 0px 48px 0px"
					font="--lead"
					color="--light"
					text-align="center"
					display="none"
				>
					Curabitur lobortis id lorem id bibendum. Ut id consectetur magna. Quisque volutpat augue enim, pulvinar lobortis nibh lacinia at. Vestibulum nec erat ut mi sollicitudin porttitor id sit amet risus. Nam tempus vel odio vitae aliquam.
				</Text>
				<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
					<Box padding="10px">
						<Image
							src="https://uploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%92%8C%E6%B5%81%E5%85%AC%E4%BC%97%E5%8F%B7%E4%BA%8C%E7%BB%B4%E7%A0%81.jpeg?v=2023-02-15T07:59:18.540Z"
							width="120px"
							max-width="100%"
							srcSet="https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%92%8C%E6%B5%81%E5%85%AC%E4%BC%97%E5%8F%B7%E4%BA%8C%E7%BB%B4%E7%A0%81.jpeg?v=2023-02-15T07%3A59%3A18.540Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%92%8C%E6%B5%81%E5%85%AC%E4%BC%97%E5%8F%B7%E4%BA%8C%E7%BB%B4%E7%A0%81.jpeg?v=2023-02-15T07%3A59%3A18.540Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%92%8C%E6%B5%81%E5%85%AC%E4%BC%97%E5%8F%B7%E4%BA%8C%E7%BB%B4%E7%A0%81.jpeg?v=2023-02-15T07%3A59%3A18.540Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%92%8C%E6%B5%81%E5%85%AC%E4%BC%97%E5%8F%B7%E4%BA%8C%E7%BB%B4%E7%A0%81.jpeg?v=2023-02-15T07%3A59%3A18.540Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%92%8C%E6%B5%81%E5%85%AC%E4%BC%97%E5%8F%B7%E4%BA%8C%E7%BB%B4%E7%A0%81.jpeg?v=2023-02-15T07%3A59%3A18.540Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%92%8C%E6%B5%81%E5%85%AC%E4%BC%97%E5%8F%B7%E4%BA%8C%E7%BB%B4%E7%A0%81.jpeg?v=2023-02-15T07%3A59%3A18.540Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/628dd8b721e43d0020958dcf/images/%E5%92%8C%E6%B5%81%E5%85%AC%E4%BC%97%E5%8F%B7%E4%BA%8C%E7%BB%B4%E7%A0%81.jpeg?v=2023-02-15T07%3A59%3A18.540Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text margin="0px 0px 0px 0px" color="--light" text-align="center" font="--base">
							微信公众号
						</Text>
					</Box>
				</Box>
				<Link
					href="mailto:qa@hlszh.com"
					text-align="center"
					color="--light"
					font="--lead"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					qa@hlszh.com
				</Link>
				<Link
					href="tel:076023700384"
					text-align="center"
					color="--light"
					font="--lead"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					0760-23700384
				</Link>
				<Text margin="0px 0px 24px 0px" font="--lead" color="--light" text-align="center">
					中国广东省中山市石岐区龙井南路3号222
				</Text>
			</Box>
		</Section>
		<Section background-color="--dark" text-align="center" padding="32px 0" quarkly-title="Footer">
			<Menu
				justify-content="center"
				font="--lead"
				font-weight="700"
				margin="-6px 0 16px"
				md-flex-direction="column"
				md-align-items="center"
				display="flex"
			>
				<Override slot="link" text-decoration="none" color="--light" padding="6px 12px" />
				<Override slot="link-active" color="--primary" />
				<Override slot="item" padding="6px 0px" />
				<Override slot="link-index">
					首页
				</Override>
				<Override slot="link-aboutme">
					关于我们
				</Override>
			</Menu>
			<Link
				text-decoration-line="none"
				variant="--base"
				color="--grey"
				hover-color="--primary"
				href="https://beian.miit.gov.cn/"
			>
				© 2023 和流数字化
				<Span
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					｜
				</Span>
				粤ICP备19102883号
			</Link>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"628dd8b721e43d0020958dcd"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script place={"endOfHead"} rawKey={"6576abcd90c79f2113783a04"}>
				{" window.onload = function() {\n      document.title = '一相科技官网-和流数字化-代决策';\n}"}
			</script>
			<script crossorigin={""} place={"endOfBody"} rawKey={"6576b3b05bc762cc138e174f"}>
				{" window.onload = function() {\n      document.title = '一相科技官网-和流数字化-代决策';\n}"}
			</script>
		</RawHtml>
	</Theme>;
});